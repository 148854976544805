import React, { RefObject, useEffect, useState, VFC } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { Button } from 'semantic-ui-react'
import { emailRegex, PAYPAL_URL } from '../../const'
import { ChargebeeCoupon } from '../../utils/coupon'
import Mutation from '../../shared/mutation'
import Notifications from '../../shared/notifications'
import { AppleBtn, GoogleBtn, PaypalBtn } from '../../shared/paypal-btn'
import Router from '../../shared/router'
import Session from '../../shared/storages/session'
import { CardToken, PaypalToken, Subscribe } from '../../type'
import { PaymentData } from './ICardProps'
import { resolveCurrency } from '../../shared/format'
import { CHARGEBEE_PAYMENT_SOURCE_TYPES as PAYMENT_TYPES } from '../../shared/types'
import {
  mutationPaypalMutation,
  mutationSubscribeMutation,
  createPaymentIntentMutation,
} from '../../graphql/checkout'

const Chargebee = require('chargebee')


const ZIP_ERROR_MATCHER = '27'
const ZIP_ERROR_MESSAGE =
  'Error: The ZIP code does not match that on file with your bank. Please contact your bank to verify your zip code.'

type CreatePaymentIntent = { createPaymentIntent: Hash }
type Props = {
  type?: PAYMENT_TYPES
  data: PaymentData
  priceIds: string[]
  addonIds?: string[]
  cardRef: RefObject<any>
  captchaRef: RefObject<any>
  loadingState: ReturnType<typeof useState<boolean>>
  coupon?: ChargebeeCoupon
  disabled?: boolean
  activateNow: boolean
  promotion: boolean
  paypalReturnPath: string
  purchaseFinished?: () => void
  price?: number
  subscriptionId?: string
  checkoutConfigId?: string
  planDetail?: { period: number, periodUnit: number }
}

const Submit: VFC<Props> = ({
  type = 'CARD',
  data: paymentData,
  priceIds,
  addonIds,
  cardRef,
  captchaRef,
  loadingState,
  coupon,
  disabled,
  activateNow,
  promotion,
  paypalReturnPath,
  purchaseFinished,
  price = 0,
  subscriptionId = '',
  checkoutConfigId = '',
  planDetail,
}) => {
  const createPaymentIntent = new Mutation<CreatePaymentIntent>(createPaymentIntentMutation)
  const subscribeMutation = new Mutation<Subscribe>(mutationSubscribeMutation)
  const paypalMutation = new Mutation<PaypalToken>(mutationPaypalMutation)

  // After success, properly redirect to the page with the necessary parameters
  const successRedirect = () => {
    const params = new URLSearchParams({
      checkoutSubscriptionId: subscriptionId,
      checkoutConfigId,
    })

    activateNow && params.append('activateNow', '1')

    if (planDetail) {
      params.append('period', planDetail.period.toString())
      params.append('periodUnit', planDetail.periodUnit.toString())
    }

    params.append('planId', priceIds[0])
    Router.redirect(`/welcome?${params}`)
  }

  // Perform the subscription mutation and handle response
  const submit = (extra: { couponCode?: string } & Hash) => {
    if (subscribeMutation.loading) {
      return
    }

    const input = {
      type,
      priceIds,
      activateNow,
      email: paymentData.email,
      signupSource: location.pathname.split('/')[1],
      checkoutSubscriptionId: subscriptionId,
      currency: resolveCurrency(),
      promotion,
      ...Session.affiliate,
      ...extra,
    }

    subscribeMutation.exec({ input }).then(action(() => {
      const result = subscribeMutation.data?.result
      if (result) {
        Notifications.success('Account created successfully')
        Session.authenticated = true
        Session.accessToken = result.token
        purchaseFinished && purchaseFinished()
        localStorage.removeItem('vs_checkout_cache')
        successRedirect()
      } else {
        loadingState[1](false)
        const error = subscribeMutation.error()
        const message = error.includes(ZIP_ERROR_MATCHER) ? ZIP_ERROR_MESSAGE : error
        Notifications.error(message, { timing: 8000 })
      }
    }))
  }

  // Submit from a payload that contains a token
  const submitWithToken = (result: CardToken) => {
    submit({
      zip: result.zip || paymentData.zip,
      firstName: result.firstName || paymentData.firstName,
      lastName: result.lastName || paymentData.lastName,
      couponCode: coupon?.id || '',
      card: { token: result.token },
    })
  }

  // Submit PayPal token and coupon code
  const submitPayPal = (token: string, couponCode?: string) => {
    submit({
      zip: '0000',
      couponCode: coupon?.id || couponCode || '',
      paypal: { token },
    })
  }

  // Helper to create the proper payment intent
  const requestPaymentIntent = async (method: string) => {
    const variables = { amount: Math.ceil(price * 100), currency: resolveCurrency(), method }
    return (await createPaymentIntent.exec(variables)).data?.createPaymentIntent
  }

  // Handle the proper payment flow with Chargebee.js token generation with 3DS
  const handleChargebeeJs = () => {
    const cardTokenizer = cardRef.current
    cardTokenizer.tokenize().then(async (result: { token: string }) => {
      const paymentIntent = await requestPaymentIntent('card')
      if (paymentIntent) {
        const threeDSHandler = await Chargebee.getInstance().load3DSHandler()
        threeDSHandler.setPaymentIntent(paymentIntent)

        await threeDSHandler.handleCardPayment({ cbToken: result.token })
        submitWithToken({ token: (paymentIntent.id as string) })
      }
    }).catch(() => {
      Notifications.error('Unable to proceed with the provided card information.')
      loadingState[1](false)
    })
  }

  // Handle submit with captcha capturing
  const handleSubmit = () => {
    if (loadingState[0]) {
      return
    }

    const captcha = captchaRef.current
    captcha.executeAsync().then((token: string) => {
      captcha.reset()
      if (token) {
        loadingState[1](true)

        // type === 'CARD'
        handleChargebeeJs()
      }
    })
  }

  // Handle the PayPal payment preparation flow
  const handlePayPal = () => {
    if (loadingState[0]) {
      return
    }

    loadingState[1](true)
    const payload = {
      id: priceIds[0],
      activateNow,
      addons: addonIds || null,
      email: paymentData.email,
      coupon: coupon?.name || '',
      currency: resolveCurrency(),
      returnPath: paypalReturnPath,
    }

    paypalMutation.exec(payload).then(() => {
      const token = paypalMutation.data?.token
      if (token) {
        window.location.href = `${PAYPAL_URL}${token}`
      } else {
        loadingState[1](false)
        Notifications.error(paypalMutation.error(), { timing: 8000 })
      }
    })
  }

  // Handle PayPal final flow
  useEffect(() => {
    if (type !== 'PAYPAL' || !Router.qs.paypal || !loadingState[0]) {
      return
    }

    const parts: string[] = (Router.qs.paypal as string).split('|')
    if (parts.at(1)?.length && !coupon) {
      return undefined
    } else if (parts.at(-1) === 'completed') {
      const token = Router.qs.token as string
      parts.splice(-1, 1, 'processing')
      Router.updateHistory(`${location.pathname}?paypal=${parts.join('|')}`)
      submitPayPal(token, parts.at(1))
    } else if (parts.at(-1) !== 'processing') {
      loadingState[1](false)
      Notifications.warning('The operation on PayPal was canceled.')
    }
  }, [coupon])

  // Handle ApplePay and GooglePay through ChargeBee
  const handleDevicePay = () => {
    if (loadingState[0]) {
      return
    }

    loadingState[1](true)
    const method = type.toLowerCase()
    requestPaymentIntent(method).then(async paymentIntent => {
      if (paymentIntent) {
        const handler = await Chargebee.getInstance().load(method.replace('_', '-'))
        handler.setPaymentIntent(paymentIntent)
        await handler.mountPaymentButton(`#${handler}-button`)
        await handler.handlePayment()

        submitWithToken({ token: (paymentIntent.id as string) })
      }
    }).catch(() => {
      Notifications.error('Unable to proceed with the selected payment method.')
      loadingState[1](false)
    })
  }

  const payMethodReady = emailRegex.test(paymentData?.email || '')
  const cardReady = [
    payMethodReady,
    paymentData?.zip?.length,
    paymentData?.firstName?.length,
    paymentData?.lastName?.length,
  ].every(Boolean)

  if (type === 'CARD') {
    return (
      <Button
        disabled={!cardReady || disabled}
        onClick={handleSubmit}
        content={activateNow ? 'Continue' : 'Start Free Trial'}
        color="red"
        size="huge"
        primary
        fluid
        loading={loadingState[0]}
      />
    )
  }

  const Component = {
    PAYPAL: PaypalBtn,
    APPLE_PAY: AppleBtn,
    GOOGLE_PAY: GoogleBtn,
  }[type]

  return (
    <Component
      disabled={!payMethodReady || disabled}
      onClick={type === 'PAYPAL' ? handlePayPal : handleDevicePay}
      loading={loadingState[0]}
    />
  )
}

const oSubmit = observer(Submit)
export { oSubmit as Submit }
