import React, { FC } from 'react'
import { Image } from 'semantic-ui-react'
import { SimpleBox } from '../../../SimpleBox'

const BadgeImage60 = require('~assets/images/badge-risk-free-60.svg')
const BadgeImage30 = require('~assets/images/badge-risk-free-60.svg')

const BADGES = {
  '30': BadgeImage30,
  '60': BadgeImage60,
}

type Props = {
  trialEnabled: boolean
  reducedMoneyBackGuarantee?: boolean
}

const Trial: FC<Props> = ({ trialEnabled, reducedMoneyBackGuarantee }) => {
  const guarantee = !trialEnabled || reducedMoneyBackGuarantee ? '30' : '60'

  return (
    <SimpleBox className="plans-trial" display="flex" gap={12} py={3} pr={2} alignItems="start">
      <Image src={BADGES[guarantee]}/>
      <div className="text text--small text--subdued">
          All VirtualShield plans come with a{' '}
        {trialEnabled && (<><span className="text">30 Day Free Trial</span> &</>)}
        {' '}<span className="text">{guarantee} Day Money-Back Guarantee</span>
        {' '}for complete peace of mind.
      </div>
    </SimpleBox>
  )
}

export { Trial }
