import { gql, useMutation } from '@apollo/client'
import { useDebouncedValue } from '@shopify/react-hooks'
import React, { useEffect, useState, VFC } from 'react'
import { Button, FormField } from 'semantic-ui-react'
import { SimpleBox } from '../../../components/SimpleBox'
import {ChargebeeCoupon, CouponData} from '../../../utils/coupon'
import { SimpleForm } from '../../../components/SimpleForm'
import { SimpleText } from '../../../components/SimpleText'
import { toMoneyWithoutCalculation, resolveCurrency } from '../../../shared/format'
import Router from '../../../shared/router'
import Cookies from 'universal-cookie'
import Notifications from '../../../shared/notifications'

const styles = require('./Discount.module.scss')
const cookies = new Cookies()

const ArrowUp = require('../../../assets/images/icons/icon-arrow-up.svg')
const ArrowDown = require('../../../assets/images/icons/icon-arrow-down.svg')

type Props = {
  planId: string
  onCouponFound: (data: ChargebeeCoupon) => void
  onRemoveCoupon: () => void
}

const Discount: VFC<Props> = ({ planId, onCouponFound, onRemoveCoupon }) => {
  const [submit, { data, loading, error }] = useMutation<CouponData>(gql`
    mutation($code: String!, $priceId: ID, $currency: String) {
      coupon: redeemCoupon(code: $code, itemPriceId: $priceId, currency: $currency) {
        id
        name
        discountType
        discountPercentage
        discountAmount
      }
    }
  `)

  const [code, setCode] = useState<string>('')
  const debouncedCode = useDebouncedValue<string>(code, { timeoutMs: 1000 })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [coupon, setCoupon] = useState<ChargebeeCoupon | undefined>()
  const [hasDebounced, setHasDebounced] = useState<boolean>(false)

  const handleConfirm = () => {
    coupon && onCouponFound && onCouponFound(coupon)
  }

  const handleCodeSubmit = async () => {
    const variables = { code: debouncedCode, priceId: planId, currency: resolveCurrency() }
    submit({ variables, fetchPolicy: 'no-cache' }).catch(() => {
      Notifications.error('Invalid coupon code')
    })
  }

  const removeCoupon = () => {
    setCoupon(undefined)
    setCode('')
    setHasDebounced(false)

    onRemoveCoupon()
  }

  const cleanUrl = () => {
    if(Router.qs.coupon) {
      const params = new URLSearchParams(location.search)
      params.delete('coupon')
      cookies.remove('coupon')
      window.history.replaceState(null, '', `?${params}${location.hash}`)
    }
  }

  const toggleForm = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (debouncedCode) {
      setHasDebounced(true)
      handleCodeSubmit()
    }

    if(debouncedCode.length === 0 && hasDebounced) {
      removeCoupon()
    }
  }, [debouncedCode, planId])

  useEffect(() => {
    if (coupon?.id === data?.coupon?.id) {
      return
    }

    setCoupon(data?.coupon)
    if (data?.coupon) {
      onCouponFound(data?.coupon)
    }
  }, [data?.coupon])

  useEffect(() => {
    const couponCode = Router.qs.coupon || cookies.get('coupon')
    if(couponCode) {
      setCode(couponCode as string)
      setIsOpen(true)
    }
  }, [Router.qs.coupon, location.search])

  return (
    <SimpleBox className="discount-form" borderRadius="8px" backgroundColor="white" padding="20px">
      <div className={styles.header} onClick={toggleForm}>
        <SimpleText size="title2">Have a coupon code?</SimpleText>
        <span className={styles.toggleButton}><img src={isOpen ? ArrowUp : ArrowDown} /></span>
      </div>
      {isOpen && (
        <SimpleBox marginTop="20px">
          <FormField>
            <label htmlFor="code">Coupon Code</label>
            <SimpleBox display="grid" gap="10px" gridTemplateColumns="auto 100px">
              <SimpleForm.Input
                id="code"
                value={code}
                type="text"
                size="small"
                onChange={({value}) => setCode(value.toUpperCase())}
                error={!!error}
                showValidation={coupon !== undefined}
                fluid
              />
              {coupon && (
                <Button
                  size="small"
                  fluid
                  className={styles.removeButton}
                  onClick={() => { removeCoupon(); cleanUrl() }}
                  loading={loading}
                  disabled={!data?.coupon}
                >Remove</Button>)}
              {!coupon && (
                <Button
                  size="small"
                  fluid
                  primary
                  color="red"
                  onClick={handleConfirm}
                  loading={loading}
                  disabled={coupon === undefined}
                >Apply</Button>)}
            </SimpleBox>
            {error && (
              <SimpleBox mt={1}>
                <div className="text red-text">{error.message}</div>
              </SimpleBox>
            )}
            {coupon && (
              <SimpleBox display="flex" alignItems="center" gap={9} mt={1}>
                <div className={styles.couponSuccess}>
                  Coupon successfully applied with{' '}
                  {coupon.discountType === 'FIXED_AMOUNT'
                    ? toMoneyWithoutCalculation(coupon.discountAmount)
                    : `${100 * coupon.discountPercentage}%`}
                  {' '}discount
                </div>
              </SimpleBox>
            )}
          </FormField>
        </SimpleBox>
      )}
    </SimpleBox>
  )
}
export { Discount }
