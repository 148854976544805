import { gql, useQuery } from '@apollo/client'
import React, { FC, useEffect } from 'react'
import { Button, Card, Header, Image } from 'semantic-ui-react'
import { useNavigation } from '../hooks/useNavigation'

type App = { id: string; key: string; name: string; notes: string; downloadUrl: string }
type AppsGroup = { key: string; title: string; apps: App[] }

const mobileApps = ['ios', 'android']

const Apps: FC = () => {
  const { setNav, resetNav } = useNavigation()

  const { data } = useQuery<{ apps: App[] }>(
    gql`
      {
        apps(published: true) {
          id
          key
          name
          notes
          downloadUrl
        }
      }
    `
  )

  const groupedApps: AppsGroup[] = []

  data?.apps &&
    data.apps.forEach((a, _index) => {
      if (mobileApps.includes(a.key)) {
        const groupIndex = groupedApps.findIndex(g => g.key === 'mobile')
        if (groupIndex === -1) {
          groupedApps.push({ key: 'mobile', title: 'Mobile Apps', apps: [a] })
        } else {
          groupedApps[groupIndex].apps.push(a)
        }
      } else {
        const groupIndex = groupedApps.findIndex(g => g.key === 'desktop')
        if (groupIndex === -1) {
          groupedApps.push({ key: 'desktop', title: 'Desktop Apps', apps: [a] })
        } else {
          groupedApps[groupIndex].apps.push(a)
        }
      }
    }, [])

  useEffect(() => {
    setNav('hidden')
    return () => {
      resetNav()
    }
  }, [])

  return (
    <div>
      <Header as="h4">Apps</Header>
      {groupedApps.map((g: AppsGroup) => (
        <div key={g.key} style={{ marginTop: 24 }}>
          {/* desktop */}
          <div className="text">{g.title}</div>
          {g.apps.map(a => (
            <Card fluid key={a.id} className="dense">
              <Card.Content>
                <div className="grid grid-apps-item">
                  <Image src={require(`~assets/images/icons/os/${a.key}.svg`)} height={58} className="mr-1" />
                  <h6>{a.name}</h6>
                  <div className="text--subdued text--small mr-auto">{a.notes}</div>
                  <Button size="big" primary onClick={() => window.open(a.downloadUrl, '_blank')}>
                    Download
                  </Button>
                </div>
              </Card.Content>
            </Card>
          ))}
        </div>
      ))}
    </div>
  )
}

export default Apps
