import React from 'react'
import { toMoney, toMoneyWithoutCalculation } from '../../../shared/format'
import { SimpleBox } from '../../SimpleBox'
import { SimpleText } from '../../SimpleText'
import { ChargebeeCoupon } from '../../../utils/coupon'
import { calculatePriceAfterCoupon } from '../../../graphql/checkout'

interface SummaryCardHeaderProps {
    userCount: number
    coupon?: ChargebeeCoupon;
    price: number
    planPeriod: number
    activateNow: boolean
    bienniallyFreeMonths?: number
}

const SummaryCardHeader: React.FC<SummaryCardHeaderProps> = ({
  userCount,
  coupon,
  price,
  planPeriod,
  activateNow,
  bienniallyFreeMonths = 0
}) => {
  const planPriceWithCoupon = calculatePriceAfterCoupon(price, coupon)
  const totalPricePerMonth = (planPriceWithCoupon / planPeriod)
  const billedPeriod = { 1: 'monthly', 12: 'yearly', 24: 'biennially' }[planPeriod]
  return(<>
    <SimpleBox
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <SimpleText size="title3" >{userCount} {userCount > 1 ? 'users' : 'user'}</SimpleText>
      <SimpleBox
        gap="8px">
        {coupon && <SimpleText size="title3" color="grey" textDecoration="lineThrough">
          {toMoneyWithoutCalculation(price)}
        </SimpleText>}
        <SimpleText size="title3">
          {toMoney(planPriceWithCoupon)}
        </SimpleText></SimpleBox>
    </SimpleBox>
    {planPeriod >= 12 && (
      <SimpleBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        marginTop="6px"
        width="100%"
      >
        <p className="checkout-gray-text">
          Billed {billedPeriod}
        </p>
        <p className="checkout-gray-text green-text">
          Only {toMoney(totalPricePerMonth)} / mo
        </p>
      </SimpleBox>
    )}
    {activateNow && bienniallyFreeMonths > 0 && (
      <SimpleBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        marginTop="4px"
        width="100%"
      >
        <SimpleBox
          className="checkout-free-text"
          background="#E8F7EB"
          borderRadius={10}
          padding="4px 10px"
        >
          +{bienniallyFreeMonths} months free
        </SimpleBox>
      </SimpleBox>
    )}
  </>)
}

export default SummaryCardHeader
