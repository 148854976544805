/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// @ts-ignore
import { Settings, isChargebeeLoaded } from '#root/config'
// @ts-ignore
import { CardComponent, CardCVV, CardExpiry, CardNumber } from '@chargebee/chargebee-js-react-wrapper'
import React, { CSSProperties, FC, useEffect, useState } from 'react'
// @ts-ignore
import { usePaymentInputs } from 'react-payment-inputs'
import { Form } from 'semantic-ui-react'
import { SimpleBox } from '../../components/SimpleBox'
import { SimpleForm } from '../../components/SimpleForm'
import { SimpleText } from '../../components/SimpleText'

// @ts-ignore
import Recaptcha from 'react-google-recaptcha'
import { cardNumberValidator } from '../../utils/cardValidator'
import {ICardProps} from './ICardProps'

const Card: FC<ICardProps> = ({ data, forwardRef, captchaRef, nameDisabled, onChange, onErrors, onValidChanged }) => {
  const { meta } = usePaymentInputs({ cardNumberValidator })
  const [numberValid, setNumberValid] = useState<boolean>(false)
  const [expiryValid, setExpiryValid] = useState<boolean>(false)
  const [cvvValid, setCvvValid] = useState<boolean>(false)

  const handleCaptcha = (value: boolean) => {
    onChange && onChange({ name: 'captcha', value })
  }

  useEffect(() => {
    onValidChanged?.(numberValid && expiryValid && cvvValid)
  }, [numberValid, expiryValid, cvvValid])

  const handleCardChange = (status: { field: string; error: string, complete: boolean }) => {
    switch(status.field) {
      case 'number':
        setNumberValid(status.complete && !status.error)
        break
      case 'expiry':
        setExpiryValid(status.complete)
        break
      case 'cvv':
        setCvvValid(status.complete)
        break
    }

    const errors = {
      [status.field]: status.error,
    }
    const errMessages = Object.values(errors).filter((message: string) => !!message)
    onErrors && onErrors(errMessages)
  }

  const styles: { [key: string]: CSSProperties } & any = {
    base: {
      height: '30px !important',
      color: '#000',
      fontWeight: '600',
      fontFamily: 'Inter, "Helvetica Neue", Arial, Helvetica, sans-serif',
      fontSize: '16px',
      lineHeight: '30px !important',
      background: '#f5f6f9',
      display: 'block',

      ':focus': {
        color: '#000',
      },

      '::placeholder': {
        color: '#787E8D',
      },

      ':focus::placeholder': {
        color: '#B0B5C2',
      },
    },
    invalid: {
      color: '#fb0023',
    },
  }

  if (!isChargebeeLoaded()) {
    return <div>loading</div>
  } else {
    return (
      <>
        <CardComponent ref={forwardRef} styles={styles} onChange={handleCardChange}>
          <SimpleBox mb={2}>
            <SimpleText size="title2">Enter card details</SimpleText>
          </SimpleBox>
          {!nameDisabled && (
            <Form.Group widths="equal">
              <Form.Field>
                <label>First Name</label>
                <SimpleForm.Input
                  name="firstName"
                  required
                  fluid
                  size="large"
                  restrict={true}
                  value={data?.firstName}
                  onChange={onChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Last Name</label>
                <SimpleForm.Input
                  name="lastName"
                  required
                  fluid
                  size="large"
                  restrict={true}
                  value={data?.lastName}
                  onChange={onChange}
                />
              </Form.Field>
            </Form.Group>)}
          <Form.Field width="16">
            <label htmlFor="cardNumber">Card Number</label>
            <CardNumber id="cardNumber" label="" placeholder="" className="cb-input" />
          </Form.Field>
          <Form.Group widths="2">
            <Form.Field>
              <label htmlFor="cardExpiry">Expiration Date</label>
              <CardExpiry id="cardExpiry" placeholder="MM / YY" />
            </Form.Field>
            <Form.Field>
              <label>CVV Code</label>
              <CardCVV placeholder="CVV" />
            </Form.Field>
            {!nameDisabled && (<Form.Field>
              <label>Zip/Postal Code</label>
              <SimpleForm.Input name="zip" value={data?.zip} fluid size="large" onChange={onChange} />
            </Form.Field>)}
            {captchaRef && (<Recaptcha
              ref={captchaRef}
              size="invisible"
              badge="bottomleft"
              sitekey={Settings.recaptchaToken}
              onChange={() => handleCaptcha(true)}
              onExpired={() => handleCaptcha(false)}
            />)}
          </Form.Group>
          {meta.isTouched && meta.error && (
            <SimpleBox>
              <span className="text red-text">{meta.error}</span>
            </SimpleBox>
          )}
        </CardComponent>
      </>
    )
  }
}

export { Card }
