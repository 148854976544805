import React, { FC, useEffect, useCallback, useMemo, useState } from 'react'
import { SimpleBox } from '../../SimpleBox'
import { Button, Image } from 'semantic-ui-react'
import { SimpleText } from '../../SimpleText'
import Tags from '../Tags'
import { gql, useMutation } from '@apollo/client'
import { attachAddon, calculateCouponDiscount } from '../../../graphql/checkout'
import Notifications from '../../../shared/notifications'
import Router from '~shared/router'
import { CouponData } from '../../../utils/coupon'
import { toMoneyWithoutCalculation as toMoney, toPercent } from '../../../shared/format'
import Decimal from 'decimal.js'
import { PCOffer } from '../../../pages/welcome'
import { Points } from '../../Plans/components/Single/components'

const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')
const WarningIcon = require('~assets/images/icons/warning.svg')
const Logo = require('~assets/images/logo-black.svg')
const LogoSymbol = require('~assets/images/logo-symbol.svg')
const TempImage = require('./temp-image.png')
const styles = require('./Upsells.module.scss')

const COUNTDOWN_TIME = 5 * 60
const DEFAULT_HEADLINE = ['Wait! Before you continue…', 'We have a special offer just for you!']

const GET_UPSELL_COUPON = gql`
  mutation {
    coupon: upsellCoupon {
      id
      name
      durationType
      discountType
      discountPercentage
      discountAmount
    }
  }
`

interface Props {
  activateNow: boolean
  upsells: PCOffer[]
  handleCloseUpsells: () => void
}

const Upsells: FC<Props> = ({ activateNow, handleCloseUpsells, upsells }) => {
  const [current, setCurrent] = useState(0)
  const [countdown, setCountdown] = useState(COUNTDOWN_TIME)

  const [attachAddonMutation, { loading }] = useMutation(attachAddon)
  const [getCouponData, { data: couponData }] = useMutation<CouponData>(GET_UPSELL_COUPON)

  const currentUpsell = upsells[current]
  const allowCoupon = currentUpsell.feature?.couponApplicable

  const handleShowNext = useCallback(() => {
    if ((current + 1) === upsells.length) {
      handleCloseUpsells()
    } else {
      setCurrent(current + 1)
      setCountdown(COUNTDOWN_TIME)
    }
  }, [current])

  const handlePurchase = useCallback(() => {
    const coupon = allowCoupon ? couponData?.coupon.id : null
    attachAddonMutation({ variables: { id: currentUpsell.itemId, coupon } }).then(() => {
      Notifications.success('Addon attached successfully')
      handleShowNext()
    }).catch(() => {
      Notifications.error('Unable to attach addon')
    })
  }, [handleShowNext, upsells, couponData])

  const [headline, features] = useMemo(() => [
    currentUpsell.feature?.headline || DEFAULT_HEADLINE,
    currentUpsell.item.featuresList?.map(feature => {
      const [title, subtitle] = feature.split(' / ')
      return { icon: PlanCheckMarkIcon, title, subtitle }
    }),
  ], [currentUpsell])

  const [originalPrice, priceWithDiscount, discountText] = useMemo(() => {
    const { coupon } = couponData || {}
    const originalPrice = new Decimal(currentUpsell.price)
    const discount = !couponData ? 0 : calculateCouponDiscount(originalPrice, coupon)
    const priceWithDiscount = originalPrice.minus(discount)

    if (!allowCoupon || !coupon) {
      const finalPrice = coupon?.durationType === 'FOREVER' ? priceWithDiscount : originalPrice

      if (currentUpsell.feature?.fakePrice) {
        const fakePrice = new Decimal(currentUpsell.feature.fakePrice)
        const discount = toPercent(1 - originalPrice.div(fakePrice).toNumber(), 0)
        return [toMoney(fakePrice.toNumber()), toMoney(finalPrice.toNumber()), discount]
      } else {
        return [toMoney(finalPrice.toNumber()), '', '']
      }
    }

    const discountText = (coupon.discountType === 'PERCENTAGE' && toPercent(coupon.discountPercentage, 0)) ||
      toMoney(coupon.discountAmount, { maximumFractionDigits: 0, minimumFractionDigits: 0 })

    return [toMoney(originalPrice.toNumber()), toMoney(priceWithDiscount.toNumber()), discountText]
  }, [couponData, currentUpsell])

  const countdownText = useMemo(() => {
    const minutes = Math.floor(countdown / 60)
    const seconds = countdown % 60
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }, [countdown])

  useEffect(() => { (countdown === 0) && handleShowNext() }, [countdown])
  useEffect(() => { getCouponData() }, [])

  useEffect(() => {
    const timer = setInterval(() => setCountdown(countdown - 1), 1000)
    return () => clearInterval(timer)
  }, [countdown])

  if (!currentUpsell.feature) {
    handleShowNext()
    return null
  }

  const themeBox = [styles.offerBox, (currentUpsell.feature.theme === 'dark' ? styles.offerBoxDark : '')]

  if (!couponData) {
    return null
  }

  const descriptionMarkup = currentUpsell.feature.description && (
    <p>{currentUpsell.feature.description}</p>
  )

  return (
    <SimpleBox width="100%" height="100%" display="flex" flexDirection="column">
      <SimpleBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Image src={Logo} height={30} id="logo" className="show-on-web" />
            <Image src={LogoSymbol} height={42} id="logo-mobile" className="show-on-mobile" />
            <div className={styles.checkout}>
              <p>|</p>
              <SimpleText size="title2" color="grey">
                Checkout
              </SimpleText>
            </div>
          </div>
          <Tags trialEnabled={!activateNow} activateNow={activateNow} />
        </div>
        <div className={styles.container}>
          <SimpleBox textAlign="center">
            {headline.map((text, index) => (
              <div key={index}><SimpleText size="large">{text}</SimpleText></div>
            ))}
          </SimpleBox>
          <SimpleBox marginTop={52} background="rgba(26, 178, 51, 0.15)" borderRadius={10}>
            {discountText && (
              <SimpleBox display="flex" alignItems="center" justifyContent="center" padding="6.5px">
                <SimpleBox
                  alignItems="center"
                  justifyContent="center"
                  padding="0px 10px"
                  borderRadius={8}
                  background="#1AB233"
                  display="flex"
                >
                  <SimpleText size="buttonText" weight="bold" color="white">
                    ONE TIME - {discountText} OFF
                  </SimpleText>
                </SimpleBox>
              </SimpleBox>
            )}
            <SimpleBox className={styles.upsellBody}>
              <div className={themeBox.join(' ')}>
                {discountText && (
                  <div className={styles.offerDetailBox}>
                    <div className={styles.offerDetail}>
                      <span>
                        <span className={styles.textGreen}>
                          {discountText} OFF
                        </span>{' '}
                        for
                      </span>
                      <span>{currentUpsell.feature.title}</span>
                    </div>
                    {descriptionMarkup}
                    <span className={styles.priceText}>
                      <span className={styles.originalPrice}>
                        {originalPrice}
                      </span>{' '}
                      {priceWithDiscount}
                    </span>
                  </div>
                ) || (
                  <div className={styles.offerDetailBox}>
                    <div className={styles.offerDetail}>
                      <span>{currentUpsell.feature.title}</span>
                    </div>
                    {descriptionMarkup}
                    <span className={styles.priceText}>
                      {originalPrice}
                    </span>
                  </div>
                )}
                <Image src={currentUpsell.feature.imageUrl || TempImage} className={styles.offerImage} />
              </div>
              {features && (
                <>
                  <div className={styles.features}>What you&apos;ll get:</div>
                  <Points items={features} columns />
                </>
              )}
              <div className={styles.actionsRow}>
                <Button
                  color="grey"
                  className={styles.skipButton}
                  onClick={handleShowNext}
                  loading={loading}
                  disabled={loading}
                >
                  No, I&apos;ll Risk It
                </Button>
                <Button
                  color="red"
                  className={styles.button}
                  onClick={handlePurchase}
                  loading={loading}
                  disabled={loading}
                >
                  Yes, add with one click!
                </Button>
              </div>
            </SimpleBox>
          </SimpleBox>
        </div>
      </SimpleBox>
      <div className={styles.expiredContainer}>
        <div className={styles.expiredRow}>
          <div className={styles.expiredBody}>
            <Image src={WarningIcon} width={24} height={24} />
            <span>Offer expires in <b>{countdownText}</b></span>
          </div>
        </div>
      </div>
    </SimpleBox>
  )
}

export default Upsells
